import React from 'react'
import { Hero, TitleCaption, Section, Container, SEO } from '../webhart-base'
import { colors, gradients } from '../webhart-base/utils/style'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'

import IGFeed from '../webhart-base/IGFeed'
import ScrollDown from '../components/ScrollDown'
import twitter from '../../static/images/icons/twitter.svg'
import strava from '../../static/images/icons/strava.svg'

import { css } from '@emotion/core'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const AboutMePage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Hi, my name is Rien"
        description="Born in 1990 in Antwerp, Belgium. Currently living in Girona, Spain. I like creating top-notch websites tailored to your needs."
      />
      <Hero
        size="66"
        color={colors.orange}
        image={data.rienhoofd.childImageSharp.fluid}
      />
      <Section>
        <Container width="wide">
          <h1>Hi, my name is Rien</h1>
          <TitleCaption>
            Born in 1990 in Antwerp, Belgium. Currently living in Girona, Spain.
            Apart from{' '}
            <OutboundLink
              href="//instagram.com/p/BpXUQqehY4H/"
              target="_blank"
              rel="noopener noreferrer"
            >
              building websites
            </OutboundLink>{' '}
            I occasionally work as a{' '}
            <OutboundLink
              href="//instagram.com/p/BfqnT5HHRcA/"
              target="_blank"
              rel="noopener noreferrer"
            >
              bike mechanic
            </OutboundLink>
            . When I'm not working, I'm probably{' '}
            <OutboundLink
              href="//instagram.com/p/Bb-abTjH1cl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              riding my bike
            </OutboundLink>{' '}
            or eating{' '}
            <OutboundLink
              href="//instagram.com/p/BacUwFoHjTz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ice-cream.
            </OutboundLink>
          </TitleCaption>
          <h2>WEBhart</h2>
          <TitleCaption color={colors.lightBlue}>
            what's in the name?
          </TitleCaption>

          <p>
            When I went to the scouts, everyone was given a totem - an animal
            based on their personality. Mine is “Trustworthy Hart” which as you
            can guess I’m quite proud of. The description goes as follows:
            <br />
            <span
              css={css`
                color: ${colors.blue};
                font-weight: 500;
                font-style: italic;
              `}
            >
              “All the movements of the Hart are graceful, proud and dignified.
              The Hart is careful but when it feels safe, it gives its
              confidence. It has excellent developed senses. In the mating
              season, the Hart often becomes irritated and angry for the
              slightest trifle. The Hart is sensitive and social, but mutually
              combative. The Hart is quick, vigilant and inseparable from their
              fawns.”
            </span>
          </p>
          <h2>follow me</h2>
          <p
            css={css`
              img {
                vertical-align: middle;
                height: 25px;
                margin: 0;
              }
              a {
                text-decoration: none;
                &:hover {
                  color: ${colors.orange};
                }
              }
            `}
          >
            <OutboundLink
              href="//twitter.com/rien_coertjens"
              target="_blank"
              rel="noopener noreferer"
            >
              <img src={twitter} alt="twitter" /> about my work
            </OutboundLink>
            <br />
            <OutboundLink
              href="//strava.com/athletes/432879"
              target="_blank"
              rel="noopener noreferer"
            >
              <img src={strava} alt="strava" /> my bike rides
            </OutboundLink>
          </p>
          <IGFeed />
        </Container>
        <ScrollDown title="work with me?" />
      </Section>
    </Layout>
  )
}

export default AboutMePage

export const AboutMePageQuery = graphql`
  query AboutMePageQuery {
    rienhoofd: file(base: { eq: "rienhoofd.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
