import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Image from 'gatsby-image'
import { css } from '@emotion/core'
import { rhythm, transition } from '../webhart-base/utils/style'
import instaheart from '../../static/images/icons/ig/instaheart.png'
import instagallery from '../../static/images/icons/ig/instagallery.png'
import instamovie from '../../static/images/icons/ig/instamovie.png'
import { mediaQueryGT } from '.'

const IGFeed = () => {
  return (
    <StaticQuery
      query={graphql`
        query IGFeedQuery {
          user: postsJson {
            userName
            userFullName
            userBio
            userFollowers
            userPostCount
            avatarLocal {
              childImageSharp {
                fixed(width: 125) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          posts: allPostsJson {
            edges {
              node {
                id
                code
                likes
                comment
                type
                text
                image {
                  childImageSharp {
                    fluid(maxWidth: 200, quality: 75) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({ user, posts }) => (
        <div>
          {/* profile info */}
          <div
            css={css`
              display: flex;
              flex-flow: column;
              ${mediaQueryGT['mobile']} {
                text-align: left;
              }
              padding: ${rhythm(1)} ${rhythm(1 / 3)} 0;
              h4,
              p {
                margin-bottom: ${rhythm(1 / 3)};
              }
              p {
                font-size: ${rhythm(1 / 2)};
              }
              *:last-child {
                margin-bottom: 0;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                .gatsby-image-wrapper {
                  margin: ${rhythm(1)} ${rhythm(1)} ${rhythm(1)} 0;
                  border-radius: 50%;
                  flex: 0 0 125px;
                }
                span {
                  font-weight: 500;
                }
              `}
            >
              <Image fixed={user.avatarLocal.childImageSharp.fixed} />
              <div
                css={css`
                  flex: 0 1 400px;
                `}
              >
                <div>
                  <h4>
                    {user.userFullName} -{' '}
                    <OutboundLink
                      href={`//instagram.com/${user.userName}`}
                      target="_blank"
                      rel="noopener"
                    >
                      @{user.userName}
                    </OutboundLink>
                  </h4>
                  <p>
                    <span>{user.userPostCount}</span> posts{' '}
                    <span>{user.userFollowers}</span> followers
                  </p>
                  <p>{user.userBio}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            css={css`
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
            `}
          >
            {/* posts */}
            {posts.edges.map(({ node: post }, i) => (
              <OutboundLink
                key={i}
                href={`//www.instagram.com/p/${post.code}`}
                target="_blank"
                rel="noopener"
                css={css`
                  line-height: 0;
                  position: relative;
                  display: block;
                  margin: ${rhythm(1 / 3)};
                  & > img {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    z-index: 9999999;
                  }
                `}
              >
                <Image fluid={post.image.childImageSharp.fluid} />
                {post.type == 'GraphSidecar' && <img src={instagallery} />}
                {post.type == 'GraphVideo' && <img src={instamovie} />}
                <div
                  css={css`
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.5);
                    z-index: 10;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    img {
                      height: 50px;
                      margin-bottom: ${rhythm(1 / 2)};
                    }
                    opacity: 0;
                    transition: ${transition};
                    &:hover {
                      opacity: 1;
                    }
                  `}
                >
                  <img src={instaheart} />
                  <span>{post.likes}</span>
                </div>
              </OutboundLink>
            ))}
          </div>
        </div>
      )}
    />
  )
}

export default IGFeed
